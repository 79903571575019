/*
 * @Author: liyu
 * @Date: 2022-05-16 15:23:58
 * @LastEditTime: 2022-08-17 18:37:13
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/solutions/education/index.tsx
 */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Banner from 'components/Banner'
import EntertainmentBanner from 'components/Entertainment/EntertainmentBanner'
import Button from 'components/Base/Button'

import SectionSubstance, { SectionSubstanceBody } from 'components/IterativeComponent/SectionSubstance'
import MousewheelSwiper from 'components/IterativeComponent/MousewheelSwiper'
import ArticleFooter from 'components/IterativeComponent/ArticleFooter'
import SectionSwiperMobile from 'components/IterativeComponent/SectionSwiperMobile'
import CardList from 'components/IterativeComponent/CardList'
import CargoCard from 'components/IterativeComponent/CargoCard'
import CourseCard from 'components/IterativeComponent/CourseCard'
import Panorama from 'components/IterativeComponent/Panorama'
import ProductCard from 'components/IterativeComponent/ProductCard'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import WebhotSwiper from 'components/WebhotSwiper'

import PartnerCard from 'components/PartnerCard'
import classnames from 'classnames'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import { Languages } from 'i18n/config'
import { isPc } from 'utils/utils'

import * as styles from './index.module.less'

const Education: React.FC = (props) => {
  const { educationYaml } = useStaticQuery(graphql`
    query {
      educationYaml {
        title
        description
        keywords
        saTitle
        banner_title
        banner_desc
        banner_buttonText
        banner_sectionTwoTitle
        banner_sectionTwoTitleMb
        banner_sectionTwoDesc
        banner_buttonHref
        banner_bg_pc
        banner_bg_mb
        banner_icon
        banner_icon_mb
        banner_dataSource {
          dataTitle
          buttonText
          buttonHref
          qrcodeUrl
          descList
        }
        trust_title
        trust_buttonText
        trust_qrcode
        trust_dataSource {
          cover
          title
          desc
        }
        product_title
        product_titleMb
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        partner_dataList {
          imgUrl
        }
        partner_voice
        partner_voice_mb
        partner_desc
        partner_voice_imgUrl
        partner_voice_imgUrlMb
        partner_voice_icon_imgUrlMb
        partner_buttonText
        partner_imgBottomText
        partner_qrcode
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        education_title
        education_imgUrl
        education_buttonText
        education_buttonLink
        education_descList {
          value
          list
        }
        panorama_title
        panorama_titleMb
        panorama_btnText
        panorama_btnCode
        panorama_btnText_two
        panorama_btnText_two_url
        panorama_dataSource {
          title
          panorama_imgUrl
        }
        cargo_title
        cargo_titleMb
        cargo_dataList {
          dataMsg
          dataTitle
          btnText
          btnHref
          imgUrlMb
          imgUrl
        }
      }
    }
  `)

  return (
    <Layout {...props}>
      <EducationState educationYaml={educationYaml} />
    </Layout>
  )
}

interface EducationStateProps {
  demoUrl?: string
  paradeUrl?: string
  lang?: Languages
  educationYaml: any
  className?: string
}
export const EducationState: React.FC<EducationStateProps> = (props) => {
  const { demoUrl, paradeUrl, lang, educationYaml } = props

  return (
    <>
      <Seo
        title={educationYaml?.title}
        description={educationYaml?.description}
        keywords={educationYaml?.keywords}
        saTitle={educationYaml?.saTitle}
      />
      <main className={classnames(styles.Education, 'lg:pt-[64px] pt-[4rem]')}>
        {/* section1 Banner */}
        <Banner
          style={{
            background: `url(${educationYaml?.banner_bg_pc?.publicURL || educationYaml?.banner_bg_pc}) no-repeat`,
          }}
          bannerIconMbSlot={
            <div className="lg:hidden w-full block absolute bottom-[-50px] text-center">
              <img className="w-[347px] h-[270px]" src={educationYaml?.banner_bg_mb} alt="" />
            </div>
          }
          className={styles.Banner}
          bannerIconClassName={styles.BannerIcon}
          bannerIcon={educationYaml?.banner_icon?.publicURL || educationYaml?.banner_icon}
          title={educationYaml?.banner_title}
          desc={educationYaml?.banner_desc}
          btnText={educationYaml?.banner_buttonText}
          btnHref={educationYaml?.banner_buttonHref}
          titleMb={educationYaml?.banner_title}
          bannerImgMb={educationYaml?.banner_icon_mb}
        />
        <div className="h-[50px] bg-[#E5EEFF] "></div>
        {/* section2 */}
        <SectionSubstance
          style={{
            paddingBottom: 0,
            backgroundImage: 'linear-gradient(to bottom,#E5EEFF,#F3F7FF)',
          }}
          className={classnames('relative z-10 ')}
          title={educationYaml?.banner_sectionTwoTitle}
          titleMb={educationYaml?.banner_sectionTwoTitleMb}
          descText={educationYaml?.banner_sectionTwoDesc}
        >
          <MousewheelSwiper
            className="hidden lg:block"
            dataSource={educationYaml?.banner_dataSource.map((item) => ({
              ...item,
              imgUrl: item?.qrcodeUrl.publicURL || item?.qrcodeUrl,
            }))}
          />
          <SectionSwiperMobile
            className="block lg:hidden mt-[2rem]"
            dataSource={educationYaml?.banner_dataSource.map((item) => ({
              ...item,
              imgUrl: item?.qrcodeUrl.publicURL || item?.qrcodeUrl,
            }))}
            slotButton={
              <Button btnType="primary" href={educationYaml?.banner_buttonHref} target="_blank">
                {educationYaml?.banner_buttonText}
              </Button>
            }
          />
        </SectionSubstance>
        {/* section3 信赖之选 */}
        <SectionSubstance
          style={{ backgroundImage: 'linear-gradient(to bottom,#F3F7FF,#fff)', paddingBottom: 0 }}
          titleMb={educationYaml?.trust_title}
          title={educationYaml?.trust_title}
          descText={educationYaml?.trust_desc}
        >
          <SectionSubstanceBody>
            <CardList
              titleColor="#1F2D3D"
              coverWidth={120}
              coverHeight={120}
              yGap={70}
              xGap={36}
              mXGap={0}
              mYGap={0}
              autoplay
              isHeight={false}
              datas={educationYaml?.trust_dataSource}
            />
            <div className="text-center">
              <ScanCodeConsultButton
                buttonText={educationYaml?.trust_buttonText}
                qrcode={educationYaml?.trust_qrcode}
              />
            </div>
          </SectionSubstanceBody>
        </SectionSubstance>
        {/* section 4 机构增效 */}
        <SectionSubstance className="!px-[10px]" style={{ paddingBottom: 0 }} titleMb={educationYaml?.education_title}>
          <CourseCard
            className={classnames('toolsContainer', styles.CourseCard)}
            title={educationYaml?.education_title}
            thumb={educationYaml?.education_imgUrl}
            buttonText={educationYaml?.education_buttonText}
            buttonHref={educationYaml?.education_buttonLink}
            descList={educationYaml?.education_descList}
            tag={{ textAlign: 'left' }}
          />
        </SectionSubstance>
        {/* section 5 全景图 */}
        <SectionSubstance title={educationYaml?.panorama_title} titleMb={educationYaml?.panorama_titleMb}>
          <SectionSubstanceBody>
            <WebhotSwiper
              classNameTitle="!w-[30%] lg:!w-auto"
              dataSource={educationYaml?.panorama_dataSource?.map((item) => ({
                ...item,
                panorama_imgUrl: item?.panorama_imgUrl,
              }))}
            />
            <div className="text-center mt-[40px] flex items-center justify-center">
              <ScanCodeConsultButton
                buttonText={educationYaml?.panorama_btnText}
                qrcode={educationYaml?.panorama_btnCode}
              />
              <a className={styles.linkBtn} target="_blank" href={educationYaml?.panorama_btnText_two_url}>
                <span className={styles.textBtn}>{educationYaml?.panorama_btnText_two}</span>
              </a>
            </div>
          </SectionSubstanceBody>
        </SectionSubstance>
        {/* section 6  合作伙伴*/}
        <SectionSubstance
          className={classnames('bg-[#F6F9FF] lg:!pt-[40px]', styles.customer)}
          title={educationYaml?.partner_voice}
          titleMb={educationYaml?.partner_voice_mb}
          descText={educationYaml?.partner_desc}
        >
          <div className="lg:w-[996px] mt-[30px] lg:mx-auto lg:mt-[40px] relative">
            <img
              src={educationYaml?.partner_voice_imgUrl?.publicURL || educationYaml?.partner_voice_imgUrl}
              className="hidden lg:block"
              alt=""
            />
            <img
              style={{
                boxShadow: '0px 2px 20px 0px rgba(153, 169, 191, 0.18)',
              }}
              src={educationYaml?.partner_voice_imgUrlMb?.publicURL || educationYaml?.partner_voice_imgUrlMb}
              className="block lg:hidden"
            />
            {/* <span className="absolute  lg:hidden w-[12.7rem] h-[3.3rem] bottom-[2.6rem] right-[2rem]">
              <img
                src={
                  educationYaml?.partner_voice_icon_imgUrlMb?.publicURL || educationYaml?.partner_voice_icon_imgUrlMb
                }
              />
            </span> */}
          </div>
          {/* <PartnerCard
            className="pt-[2rem] lg:pt-[20px]"
            dataSource={educationYaml?.partner_dataList.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
            }))}
          /> */}
          <div className="text-center mt-[3rem] lg:mt-[40px]">
            <ScanCodeConsultButton
              imgBottomText={educationYaml?.partner_imgBottomText}
              buttonText={educationYaml?.partner_buttonText}
              qrcode={educationYaml?.partner_qrcode?.publicURL || educationYaml?.partner_qrcode}
            />
          </div>
        </SectionSubstance>
        {/* section 7 更多干货 */}
        <SectionSubstance
          titleMb={educationYaml?.cargo_titleMb}
          title={educationYaml?.cargo_title}
          style={{ paddingBottom: 0 }}
        >
          <CargoCard
            className={classnames('pt-[3rem] lg:pt-[40px]', styles.CargoCard)}
            dataSource={educationYaml?.cargo_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
              imgUrlMb: item?.imgUrlMb?.publicURL || item?.imgUrlMb,
            }))}
          />
        </SectionSubstance>
        {/* section 8  了解神策产品*/}
        <SectionSubstance
          className={styles.webhotProduct}
          title={educationYaml?.product_title}
          titleMb={educationYaml?.product_titleMb}
        >
          <ProductCard
            className="!mt-[2rem] lg:!mt-[40px]"
            dataSource={educationYaml?.product_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
              imgUrlMb: item?.imgUrl?.publicURL || item?.imgUrl,
            }))}
          />
        </SectionSubstance>

        {/* 尾部 */}
        <ArticleFooter
          className={styles.educationFooter}
          title={educationYaml?.digitization_title}
          gameDsc={educationYaml?.digitization_desc}
          btnText={educationYaml?.digitization_btnText}
          btnHref={educationYaml?.digitization_btnHref}
          orderText={educationYaml?.digitization_orderText}
          orderHref={educationYaml?.digitization_orderHref}
        />
      </main>
    </>
  )
}

export default Education
