/*
 * @Author: liyu
 * @Date: 2022-05-16 10:29:19
 * @LastEditTime: 2022-07-14 17:43:13
 * @Description: file content
 * @FilePath: /sensors-www/src/components/IterativeComponent/CourseCard/index.tsx
 */
import Button from 'components/Base/Button'
import React from 'react'
import classnames from 'classnames'
import Tag from './Tag'
import * as styles from './index.module.less'

interface CourseCardProps {
  className?: string
  thumbClassName?: string
  title?: string
  thumb?: any
  descList?: {
    value?: string
    list?: string[]
  }[]
  buttonText?: string
  buttonHref?: string
  tag?: any
}
const CourseCard: React.FC<CourseCardProps> = ({
  className,
  thumbClassName,
  title,
  thumb,
  descList,
  buttonText,
  buttonHref,
  tag,
}) => {
  return (
    <div>
      {/* <div className={classnames('block lg:hidden text-[2.4rem] leading-[3.6rem] mb-[1rem]', className)}>{title}</div> */}
      <div
        className={classnames('flex flex-col lg:flex-row justify-center items-center', styles.CourseCard, className)}
      >
        <a
          className={classnames('block video-player w-full lg:w-[512px] h-auto lg:h-[312px]', thumbClassName)}
          href={buttonHref}
          target="_blank"
        >
          <img src={thumb?.publicURL || thumb} alt="" />
          <span className="play-btn"></span>
        </a>
        <div className="video-content flex flex-col justify-between  lg:w-auto h-auto lg:h-[286px] pl-0 lg:pl-[43px] w-full">
          <div className="lg:pt-[10px]">
            <div className="video-title hidden lg:block">{title}</div>
            <div className="video-tag pt-[16px] lg:pb-[17px] pb-[10px] text-center lg:text-left" style={tag}>
              {descList?.map((item, index) => {
                return (
                  item?.value && (
                    <Tag key={index} tag={item?.value} className="lg:mr-[8px] lg:ml-0 mx-[3px] mb-[10px] lg:mb-[0]" />
                  )
                )
              })}
            </div>
            {descList?.map((item, index) => {
              if (item?.list) {
                return (
                  <div className="video-type" key={index}>
                    <ul>
                      {item?.list.map((str, strIndex) => (
                        <li key={strIndex}>{str}</li>
                      ))}
                    </ul>
                  </div>
                )
              }
            })}
          </div>
          <div className=" text-center lg:text-left mt-[2rem] lg:mt-0">
            <Button btnType="primary" href={buttonHref} target="_blank">
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
