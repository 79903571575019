/* eslint-disable */
import React from 'react'
import classnames from 'classnames'
import Panorama from 'components/Panorama'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { isPc } from 'utils/utils'
import * as styles from './index.module.less'

const { useState, useRef } = React
SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation])

interface descItemProps {
  title?: string
  list?: string[]
}

interface ItemProps {
  className?: string
  panorama_btnText?: string
  panorama_btnCode?: any
  panorama_imgUrl?: any
  title: string
  icon?: string
  tabText?: React.ReactNode | string
  titleSmall?: string
  subTitle?: string
  imgUrl?: string
  imgWidth?: number
  descList?: descItemProps[]
}
interface SolutionsSwiperProps {
  className?: string
  tabWidth?: number
  dataSource?: ItemProps[]
  classNameTitle?: string
}

const SolutionsSwiper: React.FC<SolutionsSwiperProps> = ({ className, tabWidth, dataSource = [], classNameTitle }) => {
  const [state, setState] = useState({
    current: 0,
    data: [],
  })
  const mSwiper = useRef<SwiperCore>()

  const updateSwiperCurrent = (index: number) => {
    setState({
      ...state,
      current: index,
    })
  }

  const handleMouseOver = (index: number) => {
    mSwiper?.current?.autoplay.stop()
    updateSwiperCurrent(index)
    mSwiper && mSwiper?.current?.slideTo(index, 300, false)
  }

  const handleMouseOut = () => {
    mSwiper?.current?.autoplay.start()
  }

  return (
    <div className={classnames(styles.webhotSwiper, className)}>
      <ul className="tab-menu">
        {dataSource?.map((item: any, index: number) => (
          <li
            key={index}
            className={classnames(item.className, item.icon, state.current === index ? 'active' : '', classNameTitle)}
            onMouseOver={() => handleMouseOver(index)}
            onMouseOut={() => handleMouseOut()}
          >
            <span style={{ width: tabWidth }}>{item.tabText || item.title}</span>
          </li>
        ))}
      </ul>
      <Swiper
        className="WholeDomain-swiper"
        onSwiper={(swiper) => (mSwiper.current = swiper)}
        onSlideChange={() => updateSwiperCurrent(mSwiper?.current?.activeIndex as number)}
        autoHeight={isPc() ? false : true}
        allowTouchMove={isPc() ? false : true}
        autoplay={{
          delay: 6000,
        }}
      >
        {dataSource?.map((item: ItemProps) => (
          <SwiperSlide key={item.title} className={classnames(item.className)}>
            <Panorama
              imgStylePc={{ maxWidth: '1000px' }}
              className="panorama-container mx-auto"
              imgUrl={item?.panorama_imgUrl}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SolutionsSwiper
/* eslint-disable no-new */
