/*
 * @Author: liyu
 * @Date: 2022-06-16 15:17:15
 * @LastEditTime: 2022-07-14 16:55:10
 * @Description: file content
 * @FilePath: /sensors-www/src/components/IterativeComponent/CourseCard/Tag/index.tsx
 */
import React from 'react'
import classnames from 'classnames'

interface TagProps {
  className?: string
  tag?: string
}

const Tag: React.FC<TagProps> = ({ className, tag }) => {
  return (
    <div
      className={classnames(
        'inline-block  lg:h-[26px] leading-[27px] rounded-[4px] border-[1px] border-[#04CB94] border-solid text-center lg:px-[14px] px-[10px]  bg-[#DDF4F3] text-[#475669] text-[16px]',
        className,
      )}
    >
      {tag}
    </div>
  )
}

export default Tag
